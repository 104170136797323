import "../css/faq.css";
import { useState } from "react";

export default function Faq() {
  // Explicitly typing activeIndex as `number | null` to allow both number and null values
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    // Toggle the currently active index
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqData = [
    {
      question: "1. What can I expect from xHero Rewards?",
      answer:
        "The rewards and recognition platform DIP. It not only shapes or engages your company but builds trust, culture, respect, and productivity within the team. The idea is to provide employees with the best employee experience and empower them to build a workforce that thrives together. xHero improves your company's overall engagement, productivity, retention, and more.",
    },
    {
      question:
        "2. Will there be a change in pricing upon renewal of the agreement?",
      answer:
        "Upon renewal of the agreement, the pricing may be subject to adjustments based on market factors, service upgrades, or additional features that you may have opted for.",
    },
    {
      question:
        "3. Will there be any additional commercials involved in customizations of the module?",
      answer:
        "Depending on the scope of the customizations, there may be additional charges to accommodate specific requests and developments beyond the standard package.",
    },
    {
      question: "4. What type of recognition is available?",
      answer:
        "Our platform offers a variety of recognition types including social recognition, rewards for achievements, milestone celebrations, and peer-to-peer recognition.",
    },
    {
      question: "5. What is the standard validity of the agreement?",
      answer:
        "The standard validity of the agreement is typically one year, subject to renewal upon mutual agreement.",
    },
    {
      question: "6. What is your cancellation policy?",
      answer:
        "You may cancel your agreement with a 30-day notice prior to the renewal date, as outlined in our terms and conditions.",
    },
  ];

  return (
    <div>
    <section className="faq">
      <div className="faq-top">
        <h1>Got Questions?</h1>
        <img
          src={require("../img/signal wave 2.png")}
          alt="signal"
          className="mx-auto my-4"
        />
        <p>Find answers in our FAQs</p>
      </div>
      <div className="faq-item">
        {faqData.map((item, index) => (
          <div
            key={index}
            className="faq-list"
            onClick={() => toggleFAQ(index)}
          >
            {/* Question */}
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-bold">{item.question}</h4>
              <img
                src="/img/arrow.png"
                alt="toggle-arrow"
                className={`transition-transform duration-300 ${
                  activeIndex === index ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>

            {/* Answer */}
            <p
              className={`mt-2 text-gray-600 text-lg transition-all duration-500 ease-in-out overflow-hidden ${
                activeIndex === index
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              }`}
            >
              {item.answer}
            </p>
          </div>
        ))}
      </div>
    </section>

      <svg
        viewBox="0 0 1440 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg2"
      >
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#F0F0FF"
        />
      </svg>
      <section className="look">
        <div className="look-item">
          <h3 className="text-2xl font-bold">
          Stay informed Stay updated
          </h3>
          <p>
          Stay informed and ahead with xHero Satisfaction News; the preferred choice for HR professionals.
          </p>
          <a href="/contact">Check it Out</a>
        </div>
        <img src="./img/OG.png" alt="OG" />
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg3"
      >
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#F0F0FF"
        />
      </svg>
    </div>
  );
};
