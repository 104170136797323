import axiosInstance from "../utils/http";
import {
  ForgotPasswordInterface,
  LoginData,
  ResetPasswordInterface,
  SignUpData,
} from "../types/Signup";
import axios from "axios";

class AuthUser {
  async signup(payload: SignUpData) {
    try {
      const response = await axiosInstance.post("/auth/register", payload);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 422) {
          throw new Error(
            error.response.data.errors
              ? Object.values(error.response.data.errors).join(" ")
              : "Validation error"
          );
        } else {
          throw new Error("An error occurred during registration");
        }
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }

  async login(payload: LoginData) {
    try {
      const response = await axiosInstance.post("/auth/login", payload);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 422) {
          throw new Error(
            error.response.data.message
              ? Object.values(error.response.data.message).join("")
              : "Validation error"
          );
        } else {
          throw new Error("An error occurred");
        }
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }

  async forgotPassword(payload: ForgotPasswordInterface) {
    try {
      const response = await axiosInstance.post("/forgot-password", payload);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 422) {
          throw new Error(
            error.response.data.message
              ? Object.values(error.response.data.message).join("")
              : "Validation error"
          );
        } else {
          throw new Error("An error occurred");
        }
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }

  async resetPassword(payload: ResetPasswordInterface) {
    try {
      const response = await axiosInstance.post("/reset-password", payload);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 422) {
          throw new Error(
            error.response.data.message
              ? Object.values(error.response.data.message).join("")
              : "Validation error"
          );
        } else {
          throw new Error("An error occurred");
        }
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }

  async verifyEmail(url: string) {
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 422) {
          throw new Error(
            error.response.data.message
              ? Object.values(error.response.data.message).join("")
              : "Validation error"
          );
        } else {
          throw new Error("An error occurred");
        }
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  }
}

export const authService = new AuthUser();
