import "../css/style.css";
import { Link } from "react-router-dom";
export default function Platforms() {
  return (
    <div>
      <section className="platform">
        <h1>
          Outcome-Oriented Employee <br /> Recognition Software
        </h1>
        <p>
          Foster a world-class culture of engaged employees by implementing{" "}
          <br />
          meaningful recognition and rewards.
        </p>
      </section>
      <section className="built">
        <div className="ready">
          <img src={require("../img/platform1.png")} alt="brands" />
          <div className="ready-txt">
            <h2>Peer Recognition</h2>
            <p>
              At xHero, We puts peer recognition at the centre of your
              engagement strategy, cultivating employee engagement and
              connecting your employees to a common vision, to shared values,
              and most importantly, to each other.
            </p>
            <p>
              Receiving a recognition message in xHero means a lot – for your
              employees and for your business intelligence. xHero’s unique
              methodology is called Performance Recognition. Every message
              identifies both a level of impact and a connection to company
              values, providing the basis for incredibly valuable people data.
            </p>
          </div>
        </div>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
      <section className="people">
        <div className="properties">
          <div className="prop">
            <h2 style={{ marginBottom: "5%" }}>
              Enhance recognition with exceptional employee rewards.
            </h2>
            <p>
              Give your employees access to a world-class suite of rewards, no
              matter where they are. Merchandise, events, travel, charities,
              gift cards and even custom rewards unique to your company. Adding
              an employee reward program through points to your employee
              recognition experience is a fantastic way to support
              engagement  and augment compensation.
            </p>
            <Link to={"https://app.getxhero.com/register"} className="explore">
              Request a Demo
            </Link>
          </div>
          <img
            src={require("../img/reward.png")}
            alt="unity"
            style={{ width: "40%" }}
          />
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="built">
        <div className="builts">
          <img src={require("../img/plat2.png")} alt="brands" />
          <div className="built-txt">
            <h2
              style={{
                fontSize: "42px",
                lineHeight: "50.4px",
                marginBottom: "2rem",
              }}
            >
              Recognize top performer with awards
            </h2>
            <p>
              Recognizing top performers with awards and nominations is a vital
              part of our commitment to celebrating excellence within our team.
              At xHero, we believe that outstanding contributions deserve to be
              highlighted and rewarded. By nominating a colleague for a top
              performer award, you acknowledge their exceptional achievements,
              dedication, and impact on our company's success. These awards not
              only honor individual excellence but also inspire others to strive
              for greatness.
            </p>
          </div>
        </div>
        <div className="builts">
          <div className="built-txt">
            <h2
              style={{
                fontSize: "42px",
                lineHeight: "50.4px",
                marginBottom: "2rem",
              }}
            >
              Advocate for each other nomination-based award
            </h2>
            <p>
              Nominations are an opportunity to showcase the hard work and
              innovation that drive our success. When you nominate someone,
              you’re helping to build a culture that values and rewards
              exceptional performance, motivating everyone to reach new heights.
            </p>
          </div>
          <img src={require("../img/plat3.png")} alt="brands" width="300px" />
        </div>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
      <section className="people">
        <div className="properties">
          <img
            src={require("../img/Frame.png")}
            alt="unity"
            style={{ width: "40%" }}
          />
          <div className="prop">
            <h2 style={{ marginBottom: "5%" }}>
              Automated Employee Birthday and Anniversary!.
            </h2>
            <p>
              Never miss a special occasion with our automated employee birthday
              and anniversary feature. Our system ensures that every milestone
              is acknowledged, sending personalized messages and well-wishes to
              employees on their special days. This thoughtful touch strengthens
              team connections, boosts morale, and shows employees that their
              contributions are valued throughout their journey with us. With
              automation, it’s easy to maintain a culture of appreciation,
              ensuring that every birthday and work anniversary is celebrated in
              a meaningful and timely way.
            </p>
          </div>
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="built">
        <div className="ready">
          <div className="ready-txt">
            <h2>Measure Performance & Participation</h2>
            <p>
              Gain valuable insights into your team’s performance and dynamics
              with our comprehensive tools. By measuring participation levels
              and tracking key performance indicators, you can better understand
              how each team member contributes to overall goals. These insights
              allow you to identify strengths, areas for improvement, and
              patterns in collaboration. Understanding your team’s dynamic is
              crucial for fostering a productive work environment where everyone
              can thrive. With this data-driven approach, you can make informed
              decisions to optimize teamwork, enhance communication, and ensure
              that every team member is engaged and contributing to their
              fullest potential.
            </p>
          </div>
          <img src={require("../img/image.png")} alt="brands" />
        </div>
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#0D0D0D"
        />
      </svg>
    </div>
  );
}
