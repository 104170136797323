import { Link } from "react-router-dom";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { FaTimes } from "react-icons/fa";

export default function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleResources = () => {
    setIsResourcesOpen(!isResourcesOpen);
  };

  const toggleCompany = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };

  return (
    <header className="top-0 fixed w-full md:px-10 z-50 bg-[#0D0D0D] border-b border-gray-800">
      <div className="flex justify-between items-center h-20">
        <a href="/" className="ml-2">
          <img
            src="/img/logo1.png"
            alt="logo"
            className="mt-1 w-[220px] h-[60px]"
          />
        </a>

        {/* Desktop Menu */}
        <div className="hidden lg:flex items-center">
          <ul className="flex space-x-16 text-gray-300 font-bold">
            <li>
              <Link to={"/platforms"} className="hover:text-[#6666FF]">
                Platform
              </Link>
            </li>
            {/* Resources Dropdown */}
            <li className="relative group">
              <a href="/" className="flex items-center hover:text-[#6666FF]">
                Resources <IoIosArrowDown className="ml-1" />
              </a>
              <div className="absolute hidden group-hover:flex w-[200px] flex-col bg-white text-black shadow-lg">
                <Link
                  to={"/faq"}
                  className="px-4 py-2 hover:bg-[#6666FF] hover:text-white"
                >
                  FAQ
                </Link>
                {/* <Link
                  to={"/blog"}
                  className="px-4 py-2 hover:bg-[#6666FF] hover:text-white"
                >
                  Blog
                </Link> */}
                <a
                  href="#"
                  className="px-4 py-2 hover:bg-[#6666FF] hover:text-white"
                >
                  API Documentation
                </a>
              </div>
            </li>
            <li>
              <Link to={"/pricing"} className="hover:text-[#6666FF]">
                Pricing
              </Link>
            </li>
            {/* Company Dropdown */}
            <li className="relative group">
              <a href="/" className="flex items-center hover:text-[#6666FF]">
                Company <IoIosArrowDown className="ml-1"  />
              </a>
              <div className="absolute hidden group-hover:flex w-[200px] flex-col bg-white text-black shadow-lg">
                <Link
                  to={"/about"}
                  className="px-4 py-2 hover:bg-[#6666FF] hover:text-white"
                >
                  About
                </Link>
                <Link
                  to={"/contact"}
                  className="px-4 py-2 hover:bg-[#6666FF] hover:text-white"
                >
                  Contact us
                </Link>
              </div>
            </li>
          </ul>
          <div className="ml-16 space-x-16">
            <Link
              to={"https://app.getxhero.com/login"}
              className="text-gray-300 font-bold hover:text-[#6666FF]"
            >
              Log In
            </Link>
            <Link
              to={"/contact"}
              className="bg-[#6666FF] text-white px-4 py-4 rounded-lg hover:bg-[#4014bb]"
            >
              Book a demo
            </Link>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="text-gray-300 text-xl p-4 lg:hidden"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Mobile Sidebar */}
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } fixed top-0 left-0 w-3/4 h-full bg-[#171717] text-gray-300 z-40 transform transition-transform duration-300 ease-in-out lg:hidden overflow-x-hidden`}
      >
        <div className="flex flex-col items-start p-10 mt-10 space-y-6 w-full max-w-full">
          <Link
            to={"/platforms"}
            className="hover:text-[#6666FF]"
            onClick={toggleSidebar}
          >
            Platform
          </Link>

          {/* Resources Dropdown */}
          <button
            onClick={toggleResources}
            className="flex items-center w-full hover:text-[#6666FF]"
          >
            Resources <IoIosArrowDown className="ml-1" />
          </button>
          {isResourcesOpen && (
            <div className="flex flex-col bg-gray-800 text-white rounded-lg w-full max-w-full">
              <Link
                to={"/faq"}
                className="px-4 py-2 hover:bg-[#6666FF]"
                onClick={toggleSidebar}
              >
                FAQ
              </Link>
              <Link
                to={"/blog"}
                className="px-4 py-2 hover:bg-[#6666FF]"
                onClick={toggleSidebar}
              >
                Blog
              </Link>
              <a
                href="#"
                className="px-4 py-2 hover:bg-[#6666FF]"
                onClick={toggleSidebar}
              >
                API Documentation
              </a>
            </div>
          )}

          {/* Company Dropdown */}
          <button
            onClick={toggleCompany}
            className="flex items-center w-full hover:text-[#6666FF]"
          >
            Company <IoIosArrowDown className="ml-1" />
          </button>
          {isCompanyOpen && (
            <div className="flex flex-col bg-gray-800 text-white rounded-lg w-full max-w-full">
              <Link
                to={"/about"}
                className="px-4 py-2 hover:bg-[#6666FF]"
                onClick={toggleSidebar}
              >
                About
              </Link>
              <Link
                to={"/contact"}
                className="px-4 py-2 hover:bg-[#6666FF]"
                onClick={toggleSidebar}
              >
                Contact us
              </Link>
            </div>
          )}

          <Link
            to={"https://app.getxhero.com/login"}
            className="hover:text-[#6666FF]"
            onClick={toggleSidebar}
          >
            Log In
          </Link>
          <Link
            to={"/contact"}
            className="bg-[#6666FF] text-white px-4 py-2 rounded-lg hover:bg-[#6666FF]"
            onClick={toggleSidebar}
          >
            Book a demo
          </Link>
        </div>
      </div>
    </header>
  );
}
