import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";

const Pricing: React.FC = () => {
  return (
    <div>
      <section className="pricing">
        <h1 className="font-bold">Flexible Pricing to Fit Your Business</h1>
        <p>
          Choose the perfect plan for your needs. We have options that fit your
          budget.
        </p>
        <div className="plan">
          <div className="plans">
            <h3>Basic</h3>
            <p>Start with Reward & Recognition</p>
            <p>
              <span>&#x20A6;5,000</span> /member/ month
            </p>
            <a href="#"> Request a Demo</a>
            <ol className="list">
              <li>peer - peer recognition</li>
              <li>Manager - employee recognition</li>
              <li>Actionable insights and analytics</li>
              <li>Real time social media streams</li>
              <li>Mobile apps for iOS and Android</li>
              <li>Best in Customer support</li>
              <li>
                Automated milestones awards, like birthdays and work
                anniversaries
              </li>
              <li>Integration of Slack and Microsoft Teams</li>
            </ol>
          </div>
          <div className="plans2">
            <img src={require("../img/popular.png")} alt="popular" />
            <h3>PRO</h3>
            <p>Transform and scale your Reward & Recognition</p>
            <p>
              <span>&#x20A6;15,000</span> /member/ month
            </p>
            <a href="#"> Request a Demo</a>
            <ol className="list">
              <li>Customize company's reward</li>
              <li>HRIS Integration</li>
              <li>Available languages</li>
              <li>Single Signed on</li>
              <li>Budget management</li>
            </ol>
          </div>
          <div className="plans">
            <h3>Advanced</h3>
            <p>What's specially needed for your organization</p>
            <p>
              <span>&#x20A6;30,000</span> /member/ month
            </p>
            <a href="#"> Request a Demo</a>
            <ol className="list">
              <li>Team awarding</li>
              <li>Service year book</li>
              <li>Strategy and implement support</li>
              <li>Account management</li>
              <li>Multi-purpose wallet</li>
            </ol>
          </div>
        </div>
      </section>
      <svg
        viewBox="0 0 1440 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg2"
      >
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="aliceblue"
        />
      </svg>
      <section className="look">
        <div className="look-item">
          <h3 className="text-2xl font-bold">
          Stay informed Stay updated
          </h3>
          <p>
          Stay informed and ahead with xHero Satisfaction News; the preferred choice for HR professionals.
          </p>
          <a href="/contact">Check it Out</a>
        </div>
        <img src="./img/OG.png" alt="OG" />
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg3"
      >
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#F0F0FF"
        />
      </svg>
    </div>
  );
};

export default Pricing;
