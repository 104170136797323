import "../css/success.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import toastr from "toastr";
export default function Verified() {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const verificationUrl = params.get("url");

    if (verificationUrl) {
      verifyEmail(verificationUrl);
    }
  }, [location]);

  const verifyEmail = async (url: string) => {
    try {
      const response = await authService.verifyEmail(url);
      if (response.status === 200) {
        setVerificationStatus("Email verified successfully.");
        toastr.success("Email verified successfully.");
        navigate("/login");
      }
    } catch (error) {
      setVerificationStatus("Failed to verify email.");
      toastr.error("Failed to verify email.");
    }
  };
  return (
    <section className="verify">
      {verificationStatus ? (
        <>
          <img src={require("../img/success.png")} alt="" />
          <h2>Verified</h2>
          <p>You have successfully verified your account</p>
          <a href="/login">Okay</a>
        </>
      ) : (
        <p>Verifying your email...</p>
      )}
    </section>
  );
}
