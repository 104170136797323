import { Dispatch, SetStateAction } from "react";

type FormData = Record<string, any>;

export const handleChange = <T extends FormData>(
  e: React.ChangeEvent<HTMLInputElement>,
  formData: T,
  setFormData: Dispatch<SetStateAction<T>>
) => {
  const { name, value } = e.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};
