import React, { ReactNode } from "react";
import "../../css/style.css";
import Header from "./Header";
import Footer from "./Footer";
import Head from "./Head";

interface LayoutProps {
  children: ReactNode;
  title: string;
  favicon: string;
}

const Layout: React.FC<LayoutProps> = ({ title, children, favicon }) => {
  return (
    <div className="">
      <Head title={title} favicon={favicon} />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
