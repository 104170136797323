import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-[#0D0D0D] mt-16">
      <div className="border-b border-gray-800 flex flex-col md:flex-row items-center justify-between py-6 px-4 md:px-24">
    {/* Logo */}
    <img
      src={require("../../img/logo.png")}
      alt="logo"
      className="w-36 object-contain"
    />
  
    {/* Links */}
    <div className="flex flex-row flex-wrap items-center justify-center gap-6 text-gray-300 font-bold px-6 md:px-0 mt-4 md:mt-0 md:space-x-12">
      <Link to="/about" className="hover:text-[#4B0CF7]">About Us</Link>
      <Link to="/pricing" className="hover:text-[#4B0CF7]">Pricing</Link>
      <Link to="/contact" className="hover:text-[#4B0CF7]">Contact Us</Link>
      <Link to="/faq" className="hover:text-[#4B0CF7]">FAQ</Link>
      <a href="#" className="hover:text-[#4B0CF7]">Privacy</a>
      <a href="#" className="hover:text-[#4B0CF7]">T&C</a>
    </div>
  </div>

      {/* Footer Bottom */}
      <div className="flex flex-col md:flex-row items-center justify-between items-center flex-wrap py-6 px-4 md:px-24">
        <p className="text-gray-400">&copy; 2024 - xHero Technologies Limited</p>

        {/* Social Icons */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="https://facebook.com/getxhero">
            <img src="/img/Facebook.png" alt="Facebook" className="w-6" />
          </a>
          <a href="https://x.com/getxhero">
            <img src="/img/Twitter.png" alt="Twitter" className="w-6" />
          </a>
          <a href="https://instagram.com/getxhero">
            <img src="/img/Instagram.png" alt="Instagram" className="w-6" />
          </a>
          <a href="https://linkedin.com/company/getxhero">
            <img src="/img/LinkedIn.png" alt="LinkedIn" className="w-6" />
          </a>
        </div>
      </div>
    </footer>
  );
}
