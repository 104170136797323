import axiosInstance from "./http";
import { Store } from "redux";
import toastr from "toastr";

const setupInterceptors = (store: Store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.getState().auth.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      toastr.error("Request error", error.message);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error.response?.status;
      let message = "An error occurred";

      if (status) {
        switch (status) {
          case 400:
            message = "Bad Request";
            break;
          case 401:
            message = "Unauthorized";
            break;
          case 403:
            message = "Forbidden";
            break;
          case 404:
            message = "Not Found";
            break;
          case 500:
            message = "Internal Server Error";
            break;
          default:
            message = `Error ${status}`;
        }
      }

      // toastr.error(message, error.message);
      return Promise.reject(error);
    }
  );
};

export default setupInterceptors;
