import "../css/login.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { authService } from "../services/authService";
import LoadingModal from "./layouts/Loading";
import toastr from "toastr";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [confrimShowPassword, setConfirmShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get("token") || "");
    setEmail(params.get("email") || "");
  }, [location]);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);

    if (password !== password_confirmation) {
      toastr.error("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await authService.resetPassword({
        email,
        token,
        password,
        password_confirmation,
      });
      if (response.status === 200) {
        toastr.success("Password reset successfully");
        console.log(response);
        history("/login");
      }
    } catch (error) {
      if (error instanceof Error) {
        toastr.error("Failed to reset password", error.message);
      } else {
        console.error("Failed to reset password", error);
      }
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <section className="login">
      <div className="login-inner">
        <a href="/">
          <img src={require("../img/logo-1.png")} alt="" />
        </a>
        <h1 style={{ marginBottom: "5%" }}>Reset Password</h1>

        <form onSubmit={handleSubmit}>
          <span className="logs">
            <div className="pass">
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="........"
                required
                className="input2"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <span className="" onClick={togglePassword}>
                <i
                  className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  id="eye-icon"
                ></i>
              </span> */}
              <p className="pass-txt">
                Use at least 8 characters, 1 uppercase and lowercase letter, and
                1 symbol
              </p>
            </div>
            <div className="pass">
              <label>Confirm Password</label>
              <input
                type={confrimShowPassword ? "text" : "password"}
                placeholder="........"
                required
                className="input2"
                name="confirmPassword"
                value={password_confirmation}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* <span className="show-password" onClick={toggleConfirmPassword}>
                <i
                  className={`fa ${
                    confrimShowPassword ? "fa-eye" : "fa-eye-slash"
                  } sign-eye`}
                  id="eye-icon1"
                ></i>
              </span> */}
            </div>
          </span>
          <button type="submit" className="log" style={{ marginTop: "5%" }}>
            Reset Password
          </button>
        </form>
      </div>
      <LoadingModal isLoading={isLoading} />
    </section>
  );
}
