import "../css/login.css";
import React, { useState } from "react";
import { SignUpFormData } from "../types/Signup";
import toastr from "toastr";
import { authService } from "../services/authService";
import LoadingModal from "./layouts/Loading";
import { useNavigate } from "react-router-dom";
export default function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [confrimShowPassword, setConfirmShowPassword] = useState(false);
  const [formData, setFormData] = useState<SignUpFormData>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    agree: false,
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setConfirmShowPassword(!confrimShowPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const { firstname, lastname, email, password, confirmPassword, agree } =
      formData;

    if (password !== confirmPassword) {
      toastr.error("Passwords do not match");
      setIsLoading(false);
      return;
    }

    if (!agree) {
      toastr.error("You must agree to the terms and privacy policy");
      setIsLoading(false);
      return;
    }

    try {
      const response = await authService.signup({
        firstname,
        lastname,
        email,
        password,
      });
      if (response.status === 200) {
        toastr.success("Sign up successful");
        navigate("/email-verify", { state: { email } });
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        toastr.error("Sign up failed", error.message);
      } else {
        console.error("Sign up failed", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section className="login">
      <div className="login-inner">
        <a href="/">
          <img src={require("../img/logo-1.png")} alt="" />
        </a>
        <h1>Sign Up</h1>
        <p>Kindly fill up your details below</p>
        <form onSubmit={handleSubmit}>
          <div className="up-form">
            <div className="up-left1">
              <label>
                First name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="First name"
                required
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
            </div>
            <div className="up-right1">
              <label>
                Last name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="Last name"
                required
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
          <span className="logs">
            <label>Enter Your email address</label>
            <input
              type="email"
              placeholder="123@gmail.com"
              required
              className="input1"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <div className="pass">
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="........"
                required
                className="input2"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <span className="" onClick={togglePassword}>
                <i
                  className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  id="eye-icon"
                ></i>
              </span>
              <p className="pass-txt">
                Use at least 8 characters, 1 uppercase and lowercase letter, and
                1 symbol
              </p>
            </div>
            <div className="pass">
              <label>Confirm Password</label>
              <input
                type={confrimShowPassword ? "text" : "password"}
                placeholder="........"
                required
                className="input2"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <span className="show-password" onClick={toggleConfirmPassword}>
                <i
                  className={`fa ${
                    confrimShowPassword ? "fa-eye" : "fa-eye-slash"
                  } sign-eye`}
                  id="eye-icon1"
                ></i>
              </span>
            </div>
          </span>

          <div className="agree">
            <input
              type="checkbox"
              required
              name="agree"
              checked={formData.agree}
              onChange={handleChange}
            />
            <label>
              I agree to the <a href="/">Terms of service</a> and{" "}
              <a href="/">Privacy</a>
            </label>
          </div>
          <button type="submit" className="log">
            Sign Up
          </button>
        </form>
        <p className="acc">
          Don't have an account? <a href="/login">Login</a>
        </p>
      </div>

      <LoadingModal isLoading={isLoading} />
    </section>
  );
}
