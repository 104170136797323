import "../css/contact.css";
export default function Contact() {
  return (
    <div>
      <section className="contact">
        <h1>
          <span style={{ color: "#6666ff" }}>Get in Touch</span> with Us!
        </h1>
        <p>
          want your workforce to connect, engage and enhance their employee
          experience and build a happy work culture? <br />
          Connect us to know how our employee engagement solutions can do that
          for you.
        </p>
      </section>

      <section className="touch">
        <svg
          viewBox="0 0 1440 95"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="svg2"
        >
          <path
            d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
            fill="#f0f0ff"
          />
        </svg>
        <div className="touch-list">
          <div className="touch-items">
            <img src={require("../img/index-finger.png")} alt="" />
            <h3>Talk to our sales team</h3>
            <p>
              Interested in our products? <br />
              Connect to our sales team and get the best-curated solution for
              your organization.
            </p>
            <a href="#" className="get-in2">
              {" "}
              Get in Touch
            </a>
          </div>
          <div className="touch-items">
            <img src={require("../img/hand.png")} alt="" />
            <h3>Talk to our sales team</h3>
            <p>
              Interested in our products? <br />
              Connect to our sales team and get the best-curated solution for
              your organization.
            </p>
            <a href="#" className="get-in">
              {" "}
              Get in Touch
            </a>
          </div>
          <div className="touch-items">
            <img src={require("../img/headset.png")} alt="" />
            <h3>Talk to our sales team</h3>
            <p>
              Interested in our products? <br />
              Connect to our sales team and get the best-curated solution for
              your organization.
            </p>
            <a href="#" className="get-in3">
              {" "}
              Get in Touch
            </a>
          </div>
        </div>
      </section>
      <section className="cards">
        <h1>Got a Query? Drop a message</h1>
        <p>Kindly, share the following details to help serve you better.</p>
        <form action="#" className="form">
          <div className="up-form">
            <div className="up-left">
              <label>
                First name<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" placeholder="First name" required />
              <label>
                Work email<span style={{ color: "red" }}>*</span>
              </label>
              <input type="email" placeholder="email" required />
              <label>
                Numbers of employees<span style={{ color: "red" }}>*</span>
              </label>
              <input type="number" placeholder="select number" required />
            </div>
            <div className="up-right">
              <label>
                Last name<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" placeholder="Last name" required />
              <label>
                Phone number<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" placeholder="Phone number" required />
              <label>
                Company<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" placeholder="Company" required />
            </div>
          </div>
          <label>
            Enquiry Type<span style={{ color: "red" }}>*</span>
          </label>
          <select className="select" name="Enquiry type" required>
            <option value="Sales">Sales</option>
            <option value="Partnership">Partnership</option>
            <option value="Advertise with us">Advertise with us</option>
            <option value="Support">Support</option>
          </select>
          <label>
            Message<span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            placeholder="How can we help you?"
            rows={5}
            required
          ></textarea>
          <button type="submit" className="btn">
            Send
          </button>
        </form>
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg3"
      >
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#0D0D0D"
        />
      </svg>
    </div>
  );
}
