import React from "react";
import "../css/blog.css";

const Blog: React.FC = () => {
  return (
    <div>
      <section className="blog">
        <h1>Elevating Workplace culture with recognition</h1>
        <div className="blog-list">
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
          <div className="blog-items">
            <img
              src={require("../img/Rectangle 4373.png")}
              alt=""
              className="content-image"
            />
            <p className="overlay">Employee Recognition</p>
            <h3>Top 3 Employee Recognition and Rewards</h3>
            <p className="time">5 MIN READ</p>
            <p className="date">
              <strong>May 21, 2024</strong>
            </p>
            <img src={require("../img/icon.png")} alt="" className="icon" />
          </div>
        </div>
        <button className="sub2">Load More</button>
      </section>

      <svg
        viewBox="0 0 1440 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg2"
      >
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#f0f0ff"
        />
      </svg>
      <section className="look">
        <div className="look-item">
          <h3>Stay informed, stay ahead!</h3>
          <p>
            Join xHero satisfaction news, the preferred variety of professionals
            in the HR fields.
          </p>
          <input type="text" placeholder="Email" className="input" />
          <input type="submit" value="Subscribe" className="sub" />
          <img src={require("../img/Group 7.png")} alt="" />
        </div>
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg3"
      >
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#0D0D0D"
        />
      </svg>
    </div>
  );
};

export default Blog;
